.top-over-ons{
  position: relative;
  background-image: url(../../public/images/over_ons.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  @media screen and (max-width: 768px){
    background-image: url(../../public/images/over_ons_sm.webp);
  }
}

.middle_title{
  position: relative;
  font-size: 12px;
  // margin-top: 50px;
  padding: 0 30px;
  width: calc(100vw - 300px);
  //height: 30vh;
  p{
    font-size: 18px;
    margin: 5px 0;
    font-weight: 400;
  }
}

@media (max-width:950px){
  .middle_title{
    width: 100%;
    margin-bottom: 30px;
  }
}