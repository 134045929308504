.reserveren_section{
    position: relative;
    background-image: url(../../public/images/reserved.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
    @media screen and (max-width: 768px){
      //background-image: url(../../public/images/home_sm.webp);
    }
  }
.reserveren{
    padding: 20px;
    .input_wrap{
        margin-top: 20px;
        .input{
            width: 100%;
            padding: 10px;
            border: 1px solid #d6d6d6;
            border-radius: 5px;
            margin-top: 10px;
        }
    }
}