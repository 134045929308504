.top-menu {
  position: relative;
}

.menu{
  background-image: url(../../public/images/steaks.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  @media screen and (max-width: 768px){
    background-image: url(../../public/images/steaks_sm.webp);
  }
}

.category{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 50px 30px;
  @media screen and (max-width: 768px){
    padding: 0 20px 20px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 1200px;
    li {
      div{
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.lunch {
  background-image: url(../../public/images/lunch.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;

  @media screen and (max-width: 768px){
    background-image: url(../../public/images/lunch_sm.webp);
  }
}
.lunch_wrap {
  display: flex;
  justify-content: center;
  // align-items: flex-start;
  padding: 0 50px 30px;
  width: auto;
  min-width: 300px;
  margin: 0 auto;
  // height: 100%;
  // flex-direction: column;
  .lunch_box{
    display: flex;
    flex-direction: column;
    width: auto;
  }
  @media screen and (max-width: 768px){
    padding: 0 25px;
  }
}

.best_price{
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  img{
    width: 150px;
    height: 160px;
    position: absolute;
    top: -75px;
    right: 180px;
    width: 100ps;
    @media screen and (max-width: 768px){
      right: 20px;      
    }
  }
}
.best_price__text{
  color: #ffae1b;
  font-weight: 700;
}


.top_title{
  padding: 10px 50px;
  color: #000000;
  h1 {
    // font-size: 30px;
    margin: 0;
    font-weight: 400;
    @media screen and (max-width: 768px){
      font-size: 30px;
    }
  }
}

.gallery_title{
  padding: 10px 50px;
  color: #000000;
  text-align: center;
  h1 {
    // font-size: 30px;
    margin: 0;
    font-weight: 400;
    @media screen and (max-width: 768px){
      font-size: 30px;
    }
  }
}
