.top{
  position: relative;
  background-image: url(../../public/images/home.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  @media screen and (max-width: 768px){
    background-image: url(../../public/images/home_sm.webp);
  }
}

.home-middle{
  position: relative;
  font-size: 12px;
  margin: 20px 0 30px;
  padding: 0 30px;
  width: calc(100vw - 300px);

  .event_stick{
    position: absolute;
    right: 0;
    top: -120px;
    width: 150px;
    box-shadow: 0 0 10px rgba(0,0,0,0.7);
    @media screen and (max-width: 768px){
      top: -130px;
      right: 20px;
    }
  }

  h1 {
    // font-size: 30px;
    margin: 0;
    font-weight: 400;
    @media screen and (max-width: 768px){
      font-size: 30px;
    }
  }
  h2{
    
    margin: 5px 0;
    font-weight: 400;
  }
}

@media (max-width:950px){
  .home-middle, .home-bottom{
    width: 100%;
    margin-bottom: 30px;
  }
}