.top-gallery{
  position: relative;
  background: #f2ebe4;
  height: auto;
  .top_title{
    padding: 10px 50px;
    color: #000;
    text-align: center;
  }
}

.album{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
  align-items: center;
  justify-content: center;
  .album_item{
    display: flex;
    //width: 100%;
    justify-content: space-around;
    margin-bottom: 50px;
    // borderTop:'solid 1px #000',
    padding:50px;
  }
  .horizon{
    // margin: 10px 0 50px;
    max-width: 400px;
    height: auto;
    // border: solid 1px #000000;
  }
  .photo_title{
    display: flex;
    align-items: flex-start;
  }
  .vertical{
    margin: 10px;
    max-width: 400px;
    height: auto;
  }
}
@media (max-width:1200px){
  .album{
    .horizon{
      max-width: 515px;
      height: auto;
    }
    .vertical{
      max-width: 320px;
      height: auto;
    }
  }
}
@media (max-width:950px){
  .top-gallery{
    padding-top: 90px;
  }
}
@media (max-width:500px){
  .album{
    .album_item{
      //flex-direction: column;
      .photo_title{
        display:none;
      }
    }
    .horizon{
      max-width: 320px;
      height: auto;
    }
    .vertical{
      max-width: 320px;
      height: auto;
    }
  }
}