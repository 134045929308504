.header-top {
  height: 100px;
  width: 100%;
  position: relative;
  background: #303030;
  z-index: 1;
  padding: 10px 20px;
  border-bottom: solid 1px #ae965f;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 113px;
  }

  .logo-small {
    width: 42px;
  }
}
@media (min-width: 950px) {
  .header-top {
    display: none;
  }
}
@media (max-width: 500px) {
  .header-top {
    height: 82px;
  }
}

@media (max-width: 950px) {
  .hamburger-react {
    color: #ae965f;
    z-index: 33 !important;
    position: absolute !important;
    top: 26px;
    right: 20px;
  }
}

@media (max-width: 500px) {
  .hamburger-react {
    top: 17px;
  }
}

@media (max-width: 950px) {
  .menu-links {
    display: flex;
    width: 100%;
    height: auto;
    min-height: 100vh;
    background: #303030;
    position: relative;
    z-index: 2;
    top: 0;
    // align-items: center;
    padding: 90px 0 0;
    justify-content: center;
    // overflow: scroll;

    .nav-header_menu {
      display: flex;
      flex-direction: column;
      position: relative;
      height: auto;
      // overflow: scroll;

      .nav-header_link, a {
        color: #dddddd;
        font-size: 29px;
        margin-bottom: 20px;
        &:hover {
          color: #726547;
        }

        .dropdown-link {
          position: absolute;
          left: 100px;
          top: 100px;
          border: solid 1px #726547;
          background: #292828;
          padding: 0 10px;

          > a {

            p {
              position: relative;
              color: #dddddd;
              margin: 5px 0;
            }
          }
        }
      }

      .email, .phone{
        font-size: 14px;
        font-weight: 300;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
      }

      .nav-header_bottom {
        margin-bottom: 20px;
        color: #ae965f;
      }
    }
  }
}