.header {
  width: 255px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  overflow: hidden;
  background: #303030;

  .nav-header {
    display: flex;
    justify-content: space-between;
    padding: 30px 29px;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    border-right: solid 1px #726547;



    .nav-header_links {
      display: flex;
      flex-direction: column;
      //margin-top: 150px;
      .nav-header_link {
        margin-top: 10px;
        //font-family: 'Allura-Regular';
        font-weight: 300;
        font-size: 19px;
        color: #ffffff;
        padding: 0 20px;
        a span, span{
          color: #dddddd;

          &:hover{
              color: #726547;
          }
        }
        .dropdown-link{
          font-size: 19px;
          padding-left: 20px;

          p{
            color: #ffffff;
            margin: 5px 0;
            font-size: 17px;
            &:hover{
              color: #726547;
            }
          }

        }
      }

      .child{
        //cursor: auto;
        position: relative;
        .dropdown-link{
          position: absolute;
          left: 100px;
          top: 5px;
          border: solid 1px #726547;
          background: #292828;
          padding: 0 10px;
          > a {

            p {
              position: relative;
              color: #dddddd;
            }
          }
        }
          //}
        //}
      }
      @media (max-width:500px){
        //.nav-link_modal{
        //  height: 20px;
        //  font-size: 30px;
        //  .dropdown-link{
        //    position: absolute;
        //    right: -22px;
        //    bottom: -13px;
        //    padding: 10px 10px 0;
        //    z-index: 5;
        //    p{
        //      font-size: 20px;
        //      margin: 0;
        //    }
        //  }
        //}
        .child{
          //height: 105px;
          //cursor: auto;
          .services_span{
            position: relative;
            //z-index: -1;
            &:hover{
              .dropdown-link > a{
                p{
                  color: #d19469;
                }
              }
            }
          }
        }
      }
    }

    .nav-header_bottom {
      color: #ffae1b;
      font-family: 'Merienda', cursive;

      a {
        font-size: 13px;
        svg{
          margin-left: 25px;
        }
        color: #ffae1b;
        &:hover {
          color: #eadab4;
        }

      }
    }
  }
}
.submenu{
  margin-left: 10px;
}
.nav-header_logo{
  width: 100%;
  min-height: 101px;
  a{
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      color: #ae965f;
      font-family: 'Merienda', cursive;
      font-size: 19px;
    }
  }
}
@media (max-width:950px){
  .header{
    display: none;
  }
}