@font-face {
    font-family: 'Allura-Regular';
    font-weight: 900;
    src: local('CAllura-Regular'), url('../../public/fonts/allura/Allura-Regular.ttf') format('truetype');
  }

@import "base";
@import "layout";
@import "header-top";
@import "home";
@import "over-ons";
@import "menu";
@import "gallery";
@import "contact";
@import "admin";
@import "reserveren";
@import "event";