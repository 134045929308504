
.main_admin{
    // background: #d6d6d6;
    height: 100vh;
    width: 100%;
    position: absolute;

    .container_admin{
        margin: 0 auto;
        width: 900px;
        @media screen and (max-width: 768px){
            width: 100%;
        }

        .wrap_admin{
            padding: 20px;

            .reservations{
                table{
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 20px;
                    th, td{
                        border: 1px solid #d2d2d2;
                        padding: 10px;
                    }
                }
            }
            input{
                margin: 10px 0;
            }
        }
        .login{
            max-width: 300px;
            margin: 30% auto 0;
            // display: flex;

            .button_login {
                margin-top: 20px;
            }
            .error{
                color: red;
                margin-top: 10px;
            }
        }
        .admin_person{
            cursor: pointer;
            max-width: 500px;
        }
    }
}