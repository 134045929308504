.main-contact{
  background-image: url(../../public/images/contact.webp);
    @media screen and (max-width: 768px){
      background-image: url(../../public/images/contact_sm.webp);
    }
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: -1;
  padding-top: 100px;
  left: 255px;
  overflow-x: hidden;
  width: calc(100% - 255px);
  .container_contact {
    width: auto;
    margin: 0 auto;
    position: relative;
    max-width: 300px;
  }
  .wrap-contact{
    padding: 20px;
    border: solid 1px #ae965f;
    background: #fdfdfdbf;
    //bottom: 100px;
    //left: 100px;
    //position: absolute;
    border-radius: 25px;

    a{
      color: #000000;
    }
    @media screen and (max-width: 768px){
      border-radius: 5px;
    }
  }
}
@media (max-width:950px){
  .main-contact{
    width: 100%;
    left: 0;
    .wrap-contact{
      margin-top: 50px;
      padding: 20px;
      border: none;
      //background: transparent;
      //color: white;
      //bottom: 200px;
      //left: 100px;
      //position: absolute;
    }
  }
}