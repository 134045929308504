.event{
  position: relative;
  background-image: url(../../public/images/gallery/13.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
  // @media screen and (max-width: 768px){
  //   background-image: url(../../public/images/moederdag_mobile.webp);
  // }
}

.event_middle{
  position: relative;
  font-size: 12px;
  margin: 20px 0 30px;
  padding: 0 30px;
  width: 100%;

  .event_items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding: 0 30px;
    // @media screen and (max-width: 768px){
    //   flex-direction: column;
    // }
      .event_item{
        // border: 1px solid #9b9999;
        padding: 10px;
      
        img{
          width: 500px;
        }
        @media screen and (max-width: 768px){
          img{
            width: 300px;
          }
        }
        p{
          font-size: 16px;
          margin: 10px 0;
        }
      }
  }
}
