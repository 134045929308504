html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  //width: 100%;

}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}


.container-header {
  max-width: 300px;
  margin: 0 auto;
  .nav-header .nav-header_logo img{
    width: 160px;
  }
}

a{
  text-decoration: none;
}

.ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list img{
  width: 400px!important;
  height: 300px;
}

.react-photo-album{
  width: calc(100vw - 340px);
}

.main{
  position: absolute;
  top: 0;
  left: 255px;
  z-index: -1;
  overflow-x: hidden;
  width: calc(100% - 255px);
}
@media (max-width:950px){
  .main{
    left: 0;
    width: 100%;
    height: auto;
  }
}
.modal{
  overflow: hidden;
  position: relative;
}

h1{
  font-family: 'Allura-Regular';
  font-size: 59px;
  font-weight: 300;
}

input,
textarea,
select,
button {
  touch-action: manipulation;
}

// .MuiFormControl-root{
//   border: none !important;
// }
.MuiOutlinedInput-notchedOutline{
  border: none !important;
}
//.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
//  .css-ihdtdm{
//    // border: .5px solid #cdcdcd !important;
//
//    height: 0;
//  }
//}
.ant-form legend{
  display: none;
}
.Mui-error .MuiOutlinedInput-notchedOutline{
  //border-color: #cdcdcd !important;
  border: none !important;
}

.spin_center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}